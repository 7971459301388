.container {
    z-index: 950;
    padding: 10px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0px;
}

.header {
    display: flex;
    flex-direction: column;
}

.title {
    color: var(--tertiary);
    letter-spacing: 4px;
    font-size: min(6vw, 50px);
    font-weight: bold;
    margin: 5px;
}

.nav {
    width: 100%;
    height: auto;
    display: inline grid;
    align-content: center;
    justify-content: center;
    list-style: none;
    margin-block: 20px;
    padding: 0;
    overflow: hidden;
}

.nav li {
    transition: 0.3s;
    font-size: 25px;
    width: 175px;
    display: block;
    text-decoration: none;
    color: var(--tertiary);
    border-radius: 15px;
    padding: 10px;
    margin-block: 10px;
}

.nav li:hover {
    background-color: var(--secondary);
    cursor: pointer;
}

.circle {
    border: 1px solid var(--tertiary);
    border-radius: 20px;
    margin: 2px;
}

.skillName {
    font-weight: bold;
    margin-inline: 10px;
}

.skill {
    color: var(--tertiary);
    display: inline-flex;
    margin-block: 5px;
    min-width: 65%;
    justify-content: space-between;
}

.skills {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 650px) {
    .skillName {
        font-size: small;
        min-width: 70px;
    }

    .skill {
        min-width: auto;
    }

    .skills {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    .container {
        height: auto;
        position: static;
    }

    .nav {
        align-self: center;
        height: auto;
        display: flex;
    }

    .nav li {
        font-size: large;
        padding: 5px;
        margin-inline: 5px;
        width: auto;
        border-radius: 10px;
    }
}
