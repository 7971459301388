.tile {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-block: 10px;
    transition: 0.3s;
    border: 2px solid transparent;
    border-radius: 10px;
}

.tile:hover {
    border: 2px solid var(--accent);
    cursor: pointer;
}

.titleAndDate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.title {
    color: var(--secondary);
    font-size: large;
    text-align: left;
    font-weight: bold;
    display: flex;
    align-items: center;
    width: 50%;
}

.date {
    color: var(--secondary);
    font-size: larger;
    text-align: right;
    font-weight: bold;
    width: 45%;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.desc {
    color: var(--tertiary);
    text-align: left;
    font-size: medium;
    margin-top: 10px;
}

.links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.pics {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

@media screen and (max-width: 650px) {
    .tile {
        padding: 5px;
        margin-block: 2px;
    }
}
