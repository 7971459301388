.picture {
    width: min(25vw, 500px);
    border-radius: 20px;
    margin: 15px;
}

.title {
    color: var(--tertiary);
    background-color: var(--secondary);
    border-radius: 5px;
    letter-spacing: 4px;
    font-size: min(6vw, 50px);
    font-weight: bold;
    margin-block: 10px;
}

.link {
    color: var(--tertiary);
    font-size: medium;
    border-radius: 5px;
    text-decoration: none;
    padding-inline: 10px;
    padding-block: 5px;
    border: 1px solid var(--accent);
    margin-top: 15px;
    margin-bottom: 5px;
    margin-inline: 15px;
    transition: 0.3s;
}

.link:hover {
    background: var(--accent);
}

@media screen and (max-width: 930px) {
    .picture {
        border-radius: 10px;
        width: 100%;
        margin-inline: 0;
    }
}
