:root {
    --primary: #020f14;
    --secondary: #677189;
    --tertiary: #e2e2e2;
    --accent: #bd7418;
}

.App {
    text-align: center;
}

.App header {
    font-family: "Georgia", serif;
    min-height: 100vh;
    display: flex;
}

.App section {
    display: flex;
    justify-content: space-evenly;
    min-height: 100vh;
    max-width: 100%;
    background-color: var(--primary);
}

.content {
    z-index: 950;
    padding: 10px;
    max-width: 75%;
}

@media screen and (max-width: 650px) {
    .App section {
        flex-direction: column-reverse;
        justify-content: flex-end;
    }

    .content {
        max-width: 100%;
    }
}
