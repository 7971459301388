.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.objective {
    font-size: large;
    justify-content: center;
    color: var(--tertiary);
    padding-inline: 20px;
}

.socialsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.resume {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
